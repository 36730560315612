var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[(!_vm.isCorrectPin)?_c('pin-checker'):_vm._e(),_c('b-row',{staticClass:"d-flex justify-content-center mt-5"},[_c('img',{attrs:{"height":"250px","src":require("../assets/agmer-krakow.png")}})]),(_vm.returnCal.failed)?_c('b-row',{staticClass:"text-center mt-5"},[_c('h4',[_vm._v(" Niestety, nie ma jeszcze wygenerowanego kalendarza - otrzymasz stosowne powiadomienie, kiedy będzie gotowy. ")])]):_vm._e(),(_vm.isCorrectPin && !_vm.returnChoosen.failed)?_c('b-row',{staticClass:"mt-5"},[_c('div',{staticClass:"text-center m-auto"},[_c('h4',[_vm._v("Kalendarz pracy")]),_c('div',{staticClass:"button-row"},[(_vm.mode !== 'prev')?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger"},on:{"click":_vm.setPrev}},[_vm._v("Ustaw poprzedni tydzień")]):_vm._e(),(_vm.mode === 'prev')?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger"},on:{"click":_vm.setPrevTwo}},[_vm._v("Ustaw poprzedni tydzień (-2)")]):_vm._e(),(_vm.mode !== 'prev')?_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.add-shift",modifiers:{"add-shift":true}}],staticClass:"ml-2 addshift"},[_vm._v("Dobierz zmianę")]):_vm._e(),(!_vm.returnSetCalendar.failed || _vm.mode === 'prev')?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"warning"},on:{"click":_vm.changeMode}},[_vm._v(_vm._s(_vm.mode === "current" ? "Ustaw kolejny tydzień" : "Ustaw obecny tydzień"))]):_vm._e()],1)])]):_vm._e()],1),(_vm.isCorrectPin && !_vm.isMobile() && !_vm.returnChoosen.failed)?_c('div',[_c('h4',{staticClass:"ml-5"},[_vm._v("Dzień")]),(!_vm.returnChoosen.failed && _vm.returnChoosen)?_c('div',{staticClass:"days-row mb-3"},_vm._l((_vm.returnChoosen.days),function(item,index){return _c('div',{key:item.day,staticClass:"single-day"},[_c('div',{staticClass:"title"},[(
              item.day !== 1 &&
              item.month !== 1 &&
              _vm.returnChoosen.year === 2022
            )?_c('strong',[_vm._v(_vm._s(item.day)+"."+_vm._s(item.month)+"."+_vm._s(item.day > 15 ? 2024: _vm.returnChoosen.year))]):_c('strong',[_vm._v(_vm._s(item.day)+"."+_vm._s(item.month)+"."+_vm._s(item.day > 15 ? 2024: 2025))]),_c('br'),_vm._v(" "+_vm._s(`${_vm.returnFreeDays.day[index].currentDrivers}/${_vm.returnFreeDays.day[index].totalCars}`)+" ")]),_c('div',{staticClass:"dayname"},[_vm._v(" "+_vm._s(_vm.weekDays[index])+" ")]),_vm._l((item.cars),function(car){return _c('div',{key:car.reg},[(!car.notOnStation)?_c('div',{staticClass:"single-car",class:[
              car.service ? 'service' : '',
              car.isRented ? 'rented' : '',
              _vm.hasTwoShiftsOnSameDay(car.drivers[0], index, 'day'),
            ]},[_c('div',{staticClass:"name"},[(!car.grounded)?_c('strong',[_vm._v(_vm._s(car.drivers.length ? car.drivers[0].fullName : "Wolne")+_vm._s(car.drivers.length ? _vm.returnLast.find((el) => el.id === car.drivers[0].id) ? "*" : " " : ""))]):_vm._e(),(car.grounded)?_c('strong',[_vm._v(" Niedostępny ")]):_vm._e()]),_c('div',{staticClass:"regplate"},[_vm._v(" "+_vm._s(car.reg)+" "),(car.service)?_c('div',{attrs:{"id":car.reg + index}},[_c('b-icon',{attrs:{"icon":"bullseye","variant":"danger"}}),_c('b-tooltip',{attrs:{"placement":"bottom","target":car.reg + index,"triggers":"hover"}},[_c('strong',[_vm._v("Zaplanowany serwis:")]),_c('br'),_vm._l((car.service),function(item){return _c('div',{key:item.desc,staticClass:"service-box"},[_vm._v(" "+_vm._s(item.desc)),_c('br'),_vm._v(" "+_vm._s(item.serviceTime[0])+":"+_vm._s(item.serviceTime[1])+" ")])})],2)],1):_vm._e()])]):_vm._e()])})],2)}),0):_vm._e(),_c('h4',{staticClass:"ml-5"},[_vm._v("Noc")]),(!_vm.returnChoosen.failed && _vm.returnChoosen)?_c('div',{staticClass:"days-row mb-3"},_vm._l((_vm.returnChoosen.nights),function(item,index){return _c('div',{key:item.day,staticClass:"single-day"},[_c('div',{staticClass:"title"},[(
              item.day !== 1 &&
              item.month !== 1 &&
              _vm.returnChoosen.year === 2022
            )?_c('strong',[_vm._v(_vm._s(item.day)+"."+_vm._s(item.month)+"."+_vm._s(_vm.returnChoosen.year))]):_c('strong',[_vm._v(_vm._s(item.day)+"."+_vm._s(item.month)+".2024")]),_c('br'),_vm._v(" "+_vm._s(`${_vm.returnFreeDays.night[index].currentDrivers}/${_vm.returnFreeDays.night[index].totalCars}`)+" ")]),_c('div',{staticClass:"dayname"},[_vm._v(" "+_vm._s(_vm.weekDays[index])+" ")]),_vm._l((item.cars),function(car){return _c('div',{key:car.reg},[(!car.notOnStation)?_c('div',{staticClass:"single-car",class:[
              car.isRented ? 'rented' : '',
              _vm.hasTwoShiftsOnSameDay(car.drivers[0], index, 'night'),
            ]},[_c('div',{staticClass:"name"},[(!car.grounded)?_c('strong',[_vm._v(_vm._s(car.drivers.length ? car.drivers[0].fullName : "Wolne")+_vm._s(car.drivers.length ? _vm.returnLast.find((el) => el.id === car.drivers[0].id) ? "*" : " " : ""))]):_vm._e(),(car.grounded)?_c('strong',[_vm._v(" Niedostępny ")]):_vm._e()]),_c('div',{staticClass:"regplate"},[_vm._v(_vm._s(car.reg))])]):_vm._e()])})],2)}),0):_vm._e()]):_vm._e(),(_vm.isCorrectPin && _vm.isMobile() && !_vm.returnChoosen.failed)?_c('div',[(!_vm.returnChoosen.failed && _vm.returnChoosen)?_c('div',{staticClass:"days-row mb-3"},_vm._l((7),function(index){return _c('div',{key:index + '' + 'n',staticClass:"mobile-row"},[_c('div',{staticClass:"single-day"},[_c('div',{staticClass:"title"},[_c('strong',[_vm._v(_vm._s(_vm.returnChoosen.days[index - 1].day)+"."+_vm._s(_vm.returnChoosen.days[index - 1].month)+"."+_vm._s(_vm.returnChoosen.year)+" - Dzień ")]),_c('br'),_vm._v(" "+_vm._s(`${_vm.returnFreeDays.day[index - 1].currentDrivers}/${ _vm.returnFreeDays.day[index - 1].totalCars }`)+" ")]),_c('div',{staticClass:"dayname"},[_vm._v(" "+_vm._s(_vm.weekDays[index - 1])+" ")]),_vm._l((_vm.returnChoosen.days[index - 1].cars),function(car){return _c('div',{key:car.reg},[(car.drivers.length && !car.isRented)?_c('div',{staticClass:"single-car",class:[
                car.service ? 'service' : '',
                _vm.hasTwoShiftsOnSameDay(car.drivers[0], index - 1, 'day'),
              ]},[_c('div',{staticClass:"name"},[_c('strong',[_vm._v(_vm._s(car.drivers[0].fullName.split(" ")[0])),_c('br'),_vm._v(_vm._s(car.drivers[0].fullName.split(" ")[1] || car.drivers[0].fullName.split(" ")[2])+" "+_vm._s(car.drivers.length ? _vm.returnLast.find((el) => el.id === car.drivers[0].id) ? "*" : " " : "")+" ")])]),_c('div',{staticClass:"regplate"},[_vm._v(" "+_vm._s(car.reg)+" "),(car.service)?_c('div',{attrs:{"id":car.reg}},[_c('b-icon',{attrs:{"icon":"bullseye","variant":"danger","id":car.drivers[0].fullName.toString()}}),_c('b-tooltip',{staticClass:"service-mobile",attrs:{"placement":"bottom","target":car.drivers[0].fullName.toString(),"triggers":"click"}},[_c('strong',[_vm._v("Zaplanowany serwis:")]),_c('br'),_vm._l((car.service),function(item){return _c('div',{key:item.id,staticClass:"service-box"},[_vm._v(" "+_vm._s(item.desc)),_c('br'),_vm._v(" "+_vm._s(item.serviceTime[0])+":"+_vm._s(item.serviceTime[1])+" ")])})],2)],1):_vm._e()])]):_vm._e(),(!car.drivers.length && !car.isRented && !car.grounded)?_c('div',{staticClass:"single-car",class:[
                car.service ? 'service' : '',
                _vm.hasTwoShiftsOnSameDay(car.drivers[0], index - 1, 'day'),
              ]},[_vm._m(0,true),_c('div',{staticClass:"regplate"},[_vm._v(" "+_vm._s(car.reg)+" "),(car.service)?_c('div',{attrs:{"id":car.reg}},[_c('b-icon',{attrs:{"icon":"bullseye","variant":"danger","id":car.reg}}),_c('b-tooltip',{staticClass:"service-mobile",attrs:{"placement":"bottom","target":car.reg,"triggers":"click"}},[_c('strong',[_vm._v("Zaplanowany serwis:")]),_c('br'),_vm._l((car.service),function(item){return _c('div',{key:item.id,staticClass:"service-box"},[_vm._v(" "+_vm._s(item.desc)),_c('br'),_vm._v(" "+_vm._s(item.serviceTime[0])+":"+_vm._s(item.serviceTime[1])+" ")])})],2)],1):_vm._e()])]):_vm._e()])})],2),_c('div',{staticClass:"single-day"},[_c('div',{staticClass:"title"},[_c('strong',[_vm._v(_vm._s(_vm.returnChoosen.nights[index - 1].day)+"."+_vm._s(_vm.returnChoosen.nights[index - 1].month)+"."+_vm._s(_vm.returnChoosen.year)+" - Noc ")]),_c('br'),_vm._v(" "+_vm._s(`${_vm.returnFreeDays.night[index - 1].currentDrivers}/${ _vm.returnFreeDays.night[index - 1].totalCars }`)+" ")]),_c('div',{staticClass:"dayname"},[_vm._v(" "+_vm._s(_vm.weekDays[index - 1])+" ")]),_vm._l((_vm.returnChoosen.nights[index - 1].cars),function(car){return _c('div',{key:car.reg},[(car.drivers.length && !car.isRented)?_c('div',{staticClass:"single-car",class:[
                _vm.hasTwoShiftsOnSameDay(car.drivers[0], index - 1, 'night'),
              ]},[_c('div',{staticClass:"name"},[_c('strong',[_vm._v(_vm._s(car.drivers[0].fullName.split(" ")[0])),_c('br'),_vm._v(_vm._s(car.drivers[0].fullName.split(" ")[1] || car.drivers[0].fullName.split(" ")[2])+" "+_vm._s(car.drivers.length ? _vm.returnLast.find((el) => el.id === car.drivers[0].id) ? "*" : " " : "")+" ")])]),_c('div',{staticClass:"regplate"},[_vm._v(_vm._s(car.reg))])]):_vm._e(),(!car.drivers.length && !car.isRented && !car.grounded)?_c('div',{staticClass:"single-car",class:[
                _vm.hasTwoShiftsOnSameDay(car.drivers[0], index - 1, 'night'),
              ]},[_vm._m(1,true),_c('div',{staticClass:"regplate"},[_vm._v(_vm._s(car.reg))])]):_vm._e()])})],2)])}),0):_vm._e()]):_vm._e(),(_vm.returnChoosen)?_c('add-shift',{attrs:{"week":_vm.returnChoosen.week,"year":_vm.returnChoosen.year,"freeDays":_vm.returnFreeDays,"returnCal":_vm.returnChoosen,"mode":_vm.mode}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('strong',[_vm._v("Wolny ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('strong',[_vm._v("Wolny ")])])
}]

export { render, staticRenderFns }