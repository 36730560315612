<template>
  <div class="schedules">
    <b-row>
      <b-col>
        <b-card class="setting-card" shadow>
          <div class="d-flex justify-content-center">
            <b-button-group class="searchBar">
              <b-input placeholder="Wyszukaj" v-model="filter"></b-input>
              <b-dropdown
                :text="
                  selectedMonth.text
                    ? selectedMonth.text.toString()
                    : 'Wybierz tydzień'
                "
                left
                menu-class="selected"
                toggle-class="selected-button"
                variant="primary"
              >
                <b-dropdown-item
                  v-for="week in getWeeks"
                  :key="week.value"
                  :active="week.isActive"
                  @click="changeMonth"
                  active-class="cssclass"
                >
                  {{ week.text }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                v-if="selectedMonth.value"
                variant="primary"
                @click="toExcel"
                ><b-icon icon="file-earmark-excel"
              /></b-button>
            </b-button-group>
          </div>
          <b-row class="mt-5" v-if="returnUserFetch">
            <b-col
              lg="6"
              v-for="item in returnFiltered.schedules"
              :key="item.id"
            >
              <b-list-group>
                <b-list-group-item class="clickable" @click="openModal(item)">
                  <shifts-num
                    :shifts="item.attributes.selectedDays"
                    :name="item.attributes.fullName"
                    :date="item.attributes.createdAt"
                  />
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ShiftsNum from "../components/bars/ShiftsNum.vue";
import exportCsv from "../print_templates/csvExport";
export default {
  components: {
    ShiftsNum,
  },
  data() {
    return {
      selectedMonth: { text: null, value: null },
      selectedYear: null,
      isLoading: false,
      filter: null,
    };
  },
  mounted() {
    const selected = this.$store.getters["selected"];
    if (selected.year && selected.month && selected.monthVal) {
      this.selectedYear = selected.year;
      this.selectedMonth.text = selected.month;
      this.selectedMonth.value = selected.monthVal;
    }
  },
  methods: {
    changeMonth(val) {
      let month = val.target.innerText;
      let resetStatus = this.getWeeks.find((element) => element.isActive);
      if (resetStatus) {
        resetStatus.isActive = false;
      }
      let changeStatus = this.getWeeks.find((element) => element.text === month);
      changeStatus.isActive = true;
      this.selectedMonth.text = changeStatus.text;
      this.selectedMonth.value = changeStatus.value;
      this.selectedMonth.year = changeStatus.year
      this.$store.dispatch("changeSelection", {
        mode: "m",
        item: changeStatus,
      });
    },
    toExcel() {
      const headers = {
        fullName: "Imie i nazwisko".replace(/,/g, ""),
        phone: "Numer telefonu",
      };
      const schedules = this.returnUserFetch.schedules
      const array = [];
      schedules.forEach((item) => {
        array.push({
          fullName: item.fullName.replace(/,/g, ""), 
          phone: item.phone,
        });
      });
      exportCsv(headers, array, "Lista pracownikow");
    },
    changeYear(val) {
      let year = parseInt(val.target.innerText);
      let resetYear = this.returnYears.find((el) => el.isActive);
      if (resetYear) {
        resetYear.isActive = false;
      }
      let changeYear = this.returnYears.find(
        (element) => element.value === year
      );
      changeYear.isActive = true;
      this.selectedYear = year;
      this.$store.dispatch("changeSelection", { mode: "y", item: year });
    },
    async userFetchData() {
      this.isLoading = true;
      const selected = this.$store.getters["selected"];
      await this.$store.dispatch("userFetch", {
        week: selected.weekVal,
        year: selected.year,
      });
      this.isLoading = false;
    },
    openModal(item) {
      this.$router.push({ path: `/admin/podglad-grafiku/${item.id}` });
    },
  },
  computed: {
    returnYears() {
      const today = new Date();
      let year = today.getUTCFullYear();
      return [
        { value: year, isActive: false },
        { value: year + 1, isActive: false },
      ];
    },
    returnUserFetch() {
      return !this.isLoading && this.$store.getters["getUserFetch"];
    },
    returnFiltered() {
      if (this.filter && this.returnUserFetch && this.filter.length > 1) {
        let object = this.returnUserFetch.schedules.filter((item) =>
          item.attributes.fullName.toLowerCase().match(this.filter.toLowerCase())
        );
        return { schedules: object };
      } else {
        return this.returnUserFetch;
      }
    },
        getWeeks() {
      const weeks = [];
      const today = this.$dayjs().weekday(0).week()
      const nextWeek =
        this.$dayjs()
          .weekday(0)
          .week();
      for (let i = -4; i < 4; i++) {
        let makeWeek = this.$dayjs().week(today + i).weekday(0).week()
        let year = this.$dayjs().week(today + i).weekday(0).year()
        weeks.push({
          isActive: false,
          value: makeWeek,
          year: makeWeek == 1 ? year + 1 : year,
          text: `${
            this.$dayjs()
              .week(nextWeek + i)
              .weekday(0).$D
          }.${this.$dayjs()
            .week(nextWeek + i)
            .weekday(0).$M + 1}.${this.$dayjs()
            .week(nextWeek + i)
            .weekday(0)
            .year()} - ${
            this.$dayjs()
              .week(nextWeek + i)
              .weekday(6).$D
          }.${this.$dayjs()
            .week(nextWeek + i)
            .weekday(6).$M + 1}.${this.$dayjs()
            .week(nextWeek + i)
            .weekday(6)
            .year()}`,
        });
      }
      return weeks;
    },
  },
  watch: {
    selectedYear: function () {
      if (this.selectedMonth.value) {
        this.userFetchData();
      }
    },
    "selectedMonth.value": function (newMonth, oldMonth) {
      if ( newMonth !== oldMonth) {
        this.userFetchData();
      }
    },
  },
};
</script>

<style scoped>
.setting-card {
  border: 0;
  border-radius: 10px;
}
.schedules {
  width: 95%;
}
.clickable {
  cursor: pointer;
  min-height: 150px;
  display: flex;
}
.searchBar {
  flex: 0.9;
}
.clickable:hover {
  background-color: #4e73df;
  color: #fff;
}
@media (max-width: 600px) {
  .searchBar {
    flex-direction: column;
  }
}
/* search bar align */
input[type="text"]::placeholder {
  text-align: center; /* for Chrome, Firefox, Opera */
}
:-ms-input-placeholder {
  text-align: center; /* for IE 10-11 */
}
::-webkit-input-placeholder {
  text-align: center; /* for IE Edge */
}
</style>
